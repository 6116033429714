import { Component, Prop, Vue } from 'vue-property-decorator';
import { ImageStoreConfiguration } from '@/config';
import { cloneDeep } from 'lodash';
import i18n from '@/i18n';

// Components
import Contact from '@/components/contact.vue';
import MapJourney from '@/components/mapJourney.vue';
import NavigationMenu from '@/components/menu.vue';
import ProductDetails from '@/components/productDetails.vue';
import ProductDetailsLoader from '@/components/productDetailsLoader.vue';
import StepDetails from '@/components/stepDetails.vue';
import Steps from '@/components/steps.vue';
import StepLoader from '@/components/stepLoader.vue';
import LoadingModal from '@/components/loadingModal.vue';

// Services
import { GeolocationService } from '@/services/geolocationService';
import { ProductService } from '@/services/productService';
import { CompanyService } from '@/services/companyService';

// Helpers
import { StepsHelper } from '@/helpers/stepsHelper';

// Models
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { ProductionStepModel, ProductionStepStatusModel, ProductionStepSuppliersModel } from '@/models/productionStepModel';
import { SupplierModel } from '@/models/supplierModel';
import { ClientModel } from '@/models/clientModel';
import { MarkerModel } from '@/models/markerModel';
import { ProductPictureModel } from '@/models/productPictureModel';
import { StepState } from '@/models/stepState';
import { ProductDataModel } from '@/models/productModel';
import { Guid } from '@/models/guid';

@Component({
    components: { Contact, MapJourney, ProductDetails, ProductDetailsLoader, Steps, StepDetails, StepLoader, NavigationMenu, LoadingModal }
})
export default class Product extends Vue {
    @Prop()
    private orderId!: string;

    @Prop()
    private orderLineId!: string;

    @Prop()
    private isPreview!: boolean;

    private product: OrderLineDisplayModel = new OrderLineDisplayModel();

    private steps: ProductionStepSuppliersModel[] = [];

    private client: ClientModel = new ClientModel();

    private companyService: CompanyService;

    private geolocationService: GeolocationService;

    private productService: ProductService;

    private currentProductPictureIndex: number = 0;

    private isLoading: boolean = false;

    private isMapLoading: boolean = false;

    private isSupplierUpdating: boolean = false;

    private isStepsUpdating: boolean = false;

    private showMenu: boolean = false;

    private isPublished: boolean = false;

    private mapboxAccessToken: string = "";

    private selectedSupplier: SupplierModel = new SupplierModel();

    private selectedStep: ProductionStepModel = new ProductionStepModel();

    private suppliers: SupplierModel[] = [];

    private hoverIndex: number = -1;

    private scrollPosition: number = 0;

    private windowHeight: number = 0;

    private scrollPositionAtClick: number = 0;

    private mapMarkers: MarkerModel[] = [];

    private brandLogoBaseUrl: string | undefined = ImageStoreConfiguration.baseUrl;

    private detailsContent: string = "";

    private showReadMore: boolean = false;

    private showReadLess: boolean = false;

    private counter: number = 0;

    private showArrows: boolean = false;

    private blockClass: boolean = false;

    private maxlength: number = 8;

    private footprintAllCheckbox: boolean = false;

    private footprintWaterCheckbox: boolean = false;

    private footprintEnergyCheckbox: boolean = false;

    private footprintLandCheckbox: boolean = false;

    private cloneSteps: ProductionStepSuppliersModel[] = [];

    private activeStepCompanyId: string = "";

    public constructor() {
        super();
        this.companyService = new CompanyService();
        this.geolocationService = new GeolocationService();
        this.productService = new ProductService(this.$router);
    }

    private async created(): Promise<void> {
        try {
            this.isMapLoading = true;
            this.isLoading = true;
            this.orderId = this.$route.query.o as string;
            this.orderLineId = this.$route.query.l as string;
            await this.getProductAsync();
            await Promise.all([this.getMapboxTokenAsync(), this.getStepsAsync()]);
            await this.loadMarkers();
        } finally {
            this.isLoading = false;
            this.isMapLoading = false;
        }
    }

    private async getMapboxTokenAsync(): Promise<void> {
        this.mapboxAccessToken = (await this.geolocationService.getMapboxToken()).token;
        return Promise.resolve();
    }

    private async getProductAsync(): Promise<void> {
        if (this.isPreview) {
            this.product = await this.productService.getProductPreviewAsync(this.orderId, this.orderLineId);
            if (this.product.product) {
                this.isPublished = this.product.product.isPublished;
            }

        } else {
            this.product = await this.productService.getProductAsync(this.orderId, this.orderLineId);
        }
        await this.getClientAsync();
        return Promise.resolve();
    }

    private async getClientAsync(): Promise<void> {
        this.client = await this.companyService.getClientAsync(this.product.product.fromCompanyId);
        return Promise.resolve();
    }

    private async getStepsAsync(): Promise<void> {
        // let cnt = 0;
        const allOrderSteps = await this.productService.getProductionStepsAsync(this.orderId, this.orderLineId);
        const allOrderFilteredSteps = allOrderSteps.filter(s => s.stepState !== StepState.DELETED_BY_CLIENT && s.stepState !== StepState.DELETED_BY_AGENT && s.stepState !== StepState.DELETED_BY_SUPPLIER && s.stepState !== StepState.DELETED_BY_DELEGATEPARTNER);
        const defaultSteps = StepsHelper.stepSequence();
        this.steps = [];
        defaultSteps.forEach((step) => {
            const filteredStepsOfStepID = allOrderFilteredSteps.filter(s => s.stepID === step.stepID);
            if(filteredStepsOfStepID.length > 0){
                const supplierWithSteps: ProductionStepModel[] = [];
                filteredStepsOfStepID.forEach((filteredStep) => {
                    if(filteredStep.toCompanyId !== Guid.Empty){
                        const prodStep: ProductionStepModel = new ProductionStepModel();
                        prodStep.createdBy = filteredStep.createdBy;
                        prodStep.compliantAtVerificationDate = filteredStep.compliantAtVerificationDate;
                        prodStep.mainChainStepID = filteredStep.mainChainStepID;
                        prodStep.parallelSupplyChainNo = filteredStep.parallelSupplyChainNo;
                        prodStep.sequence = filteredStep.sequence;
                        prodStep.showDetailsCount = filteredStep.showDetailsCount;
                        prodStep.stepState = filteredStep.stepState;
                        prodStep.supplyChainType = filteredStep.supplyChainType;
                        prodStep.toCompanyId = filteredStep.toCompanyId;
                        prodStep.updatedAt = filteredStep.updatedAt;
                        prodStep.updatedBy = filteredStep.updatedBy;
                        prodStep.isPublished = this.product ? this.product.product.isPublished : false;
                        // prodStep.showDetails = filteredStep.showDetailsCount === 0 ? true : filteredStep.showDetails; ---- TTD-5022
                        prodStep.showDetails = filteredStep.showDetails;
                        // prodStep.showProfile = filteredStep.supplier.profile === '' || prodStep.showDetails === false ? false : filteredStep.showDetailsCount === 0 ? true : filteredStep.showProfile; --- TTD-5022
                        prodStep.showProfile = filteredStep.showProfile;
                        // prodStep.showFactoryAddress = prodStep.showDetails === false ? false : filteredStep.showDetailsCount === 0 ? true : filteredStep.showFactoryAddress; --- TTD-5022
                        prodStep.showFactoryAddress = filteredStep.showFactoryAddress;
                        // prodStep.showSupplierName = prodStep.showDetails === false ? false : filteredStep.showDetailsCount > 0 ? filteredStep.showSupplierName ? true : filteredStep.showDetails === true ? true : false : true; --- TTD-5022
                        prodStep.showSupplierName = filteredStep.showSupplierName;
                        // prodStep.showNewsLetter = filteredStep.showDetailsCount === 0 ? true : filteredStep.showNewsLetter; --- TTD-5022
                        prodStep.showProfilePicture = filteredStep.showProfilePicture;
                        prodStep.ID = filteredStep.ID;
                        prodStep.stepID = filteredStep.stepID;
                        prodStep.displayName = filteredStep.displayName;
                        prodStep.createdAt = filteredStep.createdAt;
                        // TTD-5022
                        const mainFacilitySupplierDetails = cloneDeep(filteredStep.supplier);
                        mainFacilitySupplierDetails.profilePics = mainFacilitySupplierDetails.profilePics.filter(pp => pp.addressID === mainFacilitySupplierDetails.factoryAddresses[0].id)
                        prodStep.supplier = mainFacilitySupplierDetails;
                        prodStep.isCollapsed = true;
                        supplierWithSteps.push(prodStep);
                    }
                });
                if(supplierWithSteps.length > 0){
                    const prodStepSupplier: ProductionStepSuppliersModel = {
                        displayName: step.displayName,
                        stepID: step.stepID,
                        sequence: step.sequence,
                        productionStep: supplierWithSteps,
                        isPublished: this.product ? this.product.product.isPublished : false,
                        showStep: supplierWithSteps.filter(s => s.showDetails === 'ENABLED').length > 0 ? true : false,
                        isCollapsed: true
                    }
                    this.steps.push(prodStepSupplier);
                }
            }
        })
        this.steps.sort((a, b) => {
            return a.sequence - b.sequence;
        });
        return Promise.resolve();
    }

    private mounted(): void {
        window.addEventListener('scroll', this.updateScroll);
        window.addEventListener('resize', this.onResize);
        this.windowHeight = window.innerHeight;
    }

    private beforeDestroy(): void {
        window.removeEventListener('scroll', this.updateScroll);
        window.removeEventListener('resize', this.onResize);
    }

    private get productPictureSrc(): string {
        if (this.isLoading || this.productPicturesToShow == null || this.productPicturesToShow.length === 0) {
            return "";
        }
        return this.productPicturesToShow[this.currentProductPictureIndex].docUrl;
    }

    private get productPicturesToShow(): ProductPictureModel[] {
        if (!this.product.product || this.product.product.productPictureUrls === undefined || this.product.product.productPictureUrls == null) {
            return [];
        }
        const pictures = this.product.product.productPictureUrls.filter(p => p.shown);
        pictures.sort((picture1, picture2) => {
            return picture1.sequence - picture2.sequence;
        })
        this.showArrows = pictures.length > 1 ? true: false;
        return pictures;
    }

    private get markers(): MarkerModel[] {
        return this.mapMarkers;
    }

    // Sprint 22 updated, TTD-4210
    private async loadMarkers(): Promise<void> {
        let seq = 1;
        this.steps.forEach(async step => {
            step.productionStep.forEach(async s => {
                if(s.toCompanyId !== Guid.Empty && s.showFactoryAddress !== 'DO-NOT-SHOW-ADDRESS'){
                    const address = s.supplier.factoryAddresses[0];
                    if (s.showFactoryAddress === 'FULL-ADDRESS' && address.line1 !== '' && address.postal !== '' && address.geoLat !== 0 && address.geoLong !== 0) {
                        const coordinates = [address.geoLong, address.geoLat];
                        if (this.mapMarkers.findIndex(m => m.toCompanyId === s.toCompanyId) === -1) {
                            this.mapMarkers.push({ toCompanyId: s.toCompanyId, stepID: s.ID, stepName: s.displayName, sequence: seq, coordinates: coordinates });
                            seq++;
                        }
                    } else {
                        let query = '';
                        if(s.showFactoryAddress === 'FULL-ADDRESS' || s.showFactoryAddress === 'CITY_STATE_COUNTRY'){
                            query = address.city + ", " + (address.state !== '' ? address.state + ', ' : '') + (address.country.length === 2 ? i18n.t(`countries.${address.country}`).toString() : address.country);
                        } else if (s.showFactoryAddress === 'STATE_COUNTRY') {
                            query = (address.state !== '' ? address.state + ', ' : '') + (address.country.length === 2 ? i18n.t(`countries.${address.country}`).toString() : address.country);
                        } else {
                            query = (address.country.length === 2 ? i18n.t(`countries.${address.country}`).toString() : address.country);
                        }
                        const geoState = await this.geolocationService.getCoordinates(this.mapboxAccessToken, query);
                        const mapCoordinates: any = [];
                        mapCoordinates.push(geoState.features[0].center[0]);
                        mapCoordinates.push(geoState.features[0].center[1]);
                        let coordinates: any[] = [];
                        coordinates = mapCoordinates;
                        if (this.mapMarkers.findIndex(m => m.toCompanyId === s.toCompanyId) === -1) {
                            this.mapMarkers.push({ toCompanyId: s.toCompanyId, stepID: s.ID, stepName: s.displayName, sequence: seq, coordinates: coordinates });
                            seq++;
                        }
                    }
                }
            });
        });
    }

    // Sprint 22 updated, TTD-4210
    private get currentMarker(): MarkerModel {
        if (this.activeStepCompanyId === "" || this.mapMarkers.filter(mm => mm.toCompanyId === this.activeStepCompanyId).length === 0) {
            return new MarkerModel();
        }
        this.mapMarkers.sort((a, b) => {
            return a.sequence - b.sequence;
        });
        const currentStepMarkerindex = this.mapMarkers.findIndex(m => m.toCompanyId === this.activeStepCompanyId);
        return currentStepMarkerindex !== -1 ? this.mapMarkers[currentStepMarkerindex] : new MarkerModel();
    }

    private get isSmall(): boolean {
        if (this.scrollPosition <= 10) {
            this.hoverIndex = -1;
        }

        return this.scrollPosition > 10;
    }

    private get showOverview(): boolean {
        return false;
    }

    // Sprint-22, emitter method, TTD-4210
    private async activeStep(step: ProductionStepModel): Promise<void> {
        this.activeStepCompanyId = step.toCompanyId;
        this.currentMarker;
    }

    // Sprint-22, emitter method, TTD-4210
    private hideOtherSteps(step: ProductionStepSuppliersModel): void {
        this.steps.forEach((s) => {
            if (s.stepID === step.stepID) {
                s.isCollapsed = !s.isCollapsed;
            } else {
                s.isCollapsed = true;
            }
        })
    }

    // Sprint-22, emitter method, TTD-4210
    private hideAllSteps(): void {
        this.steps.forEach((s) => s.isCollapsed = true);
    }

    // Sprint-22, emitter method, TTD-4210
    private removeActiveStep(): void {
        this.activeStepCompanyId = "";
    }

    private toggleStep(step: ProductionStepModel): void {
        const stepArr = [];
        stepArr.push(step);
    }

    // Sprint-22
    // private openDetailsPage(supplier: SupplierModel, step: ProductionStepModel): void {
    //     this.selectedSupplier = supplier;
    //     this.selectedStep = step;
    //     this.scrollPositionAtClick = window.pageYOffset;
    //     window.scrollTo(0, 0);
    //     this.showDetailsPage = true;
    // }

    // Sprint-22
    // private closeDetailsPage(): void {
    //     this.showDetailsPage = false;
    //     setTimeout(this.scrollToStep, 200);
    // }

    // Sprint-22
    // private scrollToStep(): void {
    //     window.scrollTo({
    //         top: this.scrollPositionAtClick + 50,
    //         left: 0,
    //         behavior: 'smooth'
    //     });
    // }

    private showNextPicture(): void {
        const maxIndex = this.productPicturesToShow!.length - 1;
        if (this.currentProductPictureIndex === maxIndex) {
            this.currentProductPictureIndex = 0;
        } else {
            this.currentProductPictureIndex++;
        }
    }

    private showPreviousPicture(): void {
        const maxIndex = this.productPicturesToShow!.length - 1;
        if (this.currentProductPictureIndex === 0) {
            this.currentProductPictureIndex = maxIndex;
        } else {
            this.currentProductPictureIndex--;
        }
    }

    private openMenu(): void {
        this.showMenu = true;
    }

    private closeMenu(): void {
        this.showMenu = false;
    }

    private updateScroll(): void {
        this.scrollPosition = window.scrollY;
    }

    private onResize(): void {
        this.windowHeight = window.innerHeight;
    }

    private setFootprintCheckboxState(footprintAll: boolean, footprintWater: boolean, footprintEnergy: boolean, footprintLand: boolean): void {
        this.footprintAllCheckbox = footprintAll;
        this.footprintWaterCheckbox = footprintWater;
        this.footprintEnergyCheckbox = footprintEnergy;
        this.footprintLandCheckbox = footprintLand;
    }

    private updatedStepDetails(step: ProductionStepSuppliersModel, product: OrderLineDisplayModel): void {
        const index = this.cloneSteps.findIndex(cs => cs.stepID === step.stepID);
        if(index === -1){
            this.cloneSteps.push(step);
        } else {
            this.cloneSteps.splice(index, 1, step);
        }
    }

    // After TTD-5022 not required
    // private async updateStep(): Promise<void> {
    //     try {
    //         this.isStepsUpdating = true;
    //         const updatedSteps: ProductionStepStatusModel[] = [];
    //         this.steps.forEach(step => {
    //             const cloneStep = this.cloneSteps.filter(cs => cs.stepID === step.stepID);
    //             if(cloneStep !== undefined && cloneStep.length > 0){
    //                 step.productionStep.forEach(ps => {
    //                     const supplierDetails = cloneStep[0].productionStep.filter(s => s.toCompanyId === ps.toCompanyId);
    //                     const updatedStep: ProductionStepStatusModel = {
    //                         ID: ps.ID,
    //                         showDetails: supplierDetails !== undefined && supplierDetails.length > 0 ? supplierDetails[0].showDetails : ps.showDetails,
    //                         showProfile: supplierDetails !== undefined && supplierDetails.length > 0 ? supplierDetails[0].showProfile : ps.showProfile,
    //                         showFactoryAddress: supplierDetails !== undefined && supplierDetails.length > 0 ? supplierDetails[0].showFactoryAddress : ps.showFactoryAddress,
    //                         supplyChainType: ps.supplyChainType,
    //                         parallelSupplyChainNo: ps.parallelSupplyChainNo,
    //                         mainChainStepID: ps.mainChainStepID,
    //                         showSupplierName: supplierDetails !== undefined && supplierDetails.length > 0 ? supplierDetails[0].showSupplierName : ps.showSupplierName
    //                     };
    //                     updatedSteps.push(updatedStep);
    //                 })
    //             } else {
    //                 step.productionStep.forEach(ps => {
    //                     const updatedStep: ProductionStepStatusModel = {
    //                         ID: ps.ID,
    //                         showDetails: step.showStep,
    //                         showProfile: ps.showProfile,
    //                         showFactoryAddress: ps.showFactoryAddress,
    //                         supplyChainType: ps.supplyChainType,
    //                         parallelSupplyChainNo: ps.parallelSupplyChainNo,
    //                         mainChainStepID: ps.mainChainStepID,
    //                         showSupplierName: ps.showSupplierName
    //                     };
    //                     updatedSteps.push(updatedStep);
    //                 })
    //             }
    //         });
    //         const data: ProductDataModel = {
    //             newsLetter: {
    //                 orderId: this.orderId,
    //                 lineID: this.orderLineId,
    //                 showNewsLetter: this.product.product.showNewsLetter
    //             },
    //             showPage: updatedSteps,
    //             showFootprint: {
    //                 ID: this.product.footprint.length>0?this.product.footprint[0].ID:'',
    //                 allFootprintIcon: this.footprintAllCheckbox === true ? "SHOW" : "HIDE",
    //                 showWaterIcon: this.footprintWaterCheckbox === true ? "SHOW" : "HIDE",
    //                 showCO2Icon: this.footprintEnergyCheckbox === true ? "SHOW" : "HIDE",
    //                 showLandIcon: this.footprintLandCheckbox === true ? "SHOW" : "HIDE",

    //             }
    //         };
    //         if (data) {
    //             const result = await this.productService.updateProductStepAsync(data);
    //             if (result) {
    //                 NotificationHelper.createSucceededNotification(i18n.t('global.notifications.steps_update_succeeded').toString());
    //             }
    //         }
    //     } finally {
    //         this.isStepsUpdating = false;
    //     }
    // }

    // private async updateStepDetails(): Promise<void> {
    //     try {
    //         this.steps.forEach(step => {
    //             const body = {
    //                 ID: step.ID,
    //                 showProfile: step.showProfile,
    //                 showFactoryAddress: step.showFactoryAddress,
    //                 showNewsLetter: step.showNewsLetter
    //             };
    //             const result = this.productService.updateProductStepDetailsAsync(this.product.product.fromCompanyId, body);
    //         });
    //     } finally {
    //         this.isSupplierUpdating = false;
    //     }
    // }

    private get brandLogoSrc(): string {
        if (!this.product.clientDetails || this.product.clientDetails.brandLogoURL === undefined || this.product.clientDetails.brandLogoURL== null || this.product.clientDetails.brandLogoURL== "") {
            return "";
        } else {
            const brandLogoURL = this.product.clientDetails.brandLogoURL;
            return `${this.brandLogoBaseUrl}${brandLogoURL}`;
        }
    }
}
