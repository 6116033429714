import { Component, Emit, Prop, Watch, Vue } from 'vue-property-decorator';
import { ProductionStepModel, ProductionStepSuppliersModel } from '@/models/productionStepModel';
import { SupplierModel, SupplierStatusModel } from '@/models/supplierModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { CompanyService } from '@/services/companyService';
import i18n from "@/i18n";
import {cloneDeep, uniqBy} from 'lodash';

@Component
export default class Step extends Vue {

    @Prop()
    private step!: ProductionStepSuppliersModel;

    @Prop()
    private windowHeight!: number;

    @Prop()
    private scrollPosition!: number;

    private companyService: CompanyService;

    @Prop()
    private product!: OrderLineDisplayModel;

    private supplier: SupplierModel = new SupplierModel();

    private height: number = 0;

    private top: number = 0;

    private stepHtmlElement: HTMLElement | null = null;

    private showStepPartners: boolean = false;

    private sliderImages: string[] = new Array();

    private displayName: string = "";

    private showSupplier: boolean = false;

    private cloneStep: ProductionStepSuppliersModel = new ProductionStepSuppliersModel;

    private isShowStep: boolean = false;

    public constructor() {
        super();
        this.companyService = new CompanyService();
    }

    private async created(): Promise<void> {
        this.cloneStep = cloneDeep(this.step);
        this.cloneStep.productionStep = uniqBy(this.cloneStep.productionStep, 'toCompanyId');
        if (this.step && this.step.displayName !== "") {
            this.displayName = i18n.t(`enums.steps.${this.step.stepID}`).toString().toLowerCase();
        }
    }

    private mounted(): void {
        this.stepHtmlElement = this.$refs.step as HTMLElement;
        if (this.stepHtmlElement == null) {
            return;
        }
        this.top = this.stepHtmlElement.offsetTop;
        this.height = 72;
        // this.setStepCheckboxStatus();
    }

    private showSupplierDetail(id: string, status: boolean): void {
        const index = this.cloneStep.productionStep.findIndex(s => s.ID === id);
        this.cloneStep.productionStep.forEach((step, stepIndex) => {
            if (stepIndex !== index) {
                step.isCollapsed = true;
            }
        })
        this.cloneStep.productionStep[index].isCollapsed = status;
        !status ? this.activeStep(this.cloneStep.productionStep[index]) : this.removeActiveStep();
    }

    // Not required after TTD-5022
    // private setStepCheckboxStatus() {
    //     //For step
    //     const checkboxLable = document.getElementById("checkbox-lable-" + this.cloneStep.sequence) as HTMLInputElement;
    //     if (this.cloneStep.showStep && !checkboxLable.classList.contains('checked')) {
    //         checkboxLable.classList.add("checked");
    //     } else {
    //         checkboxLable.classList.remove("checked");
    //     }

    //     //For step Supplier
    //     this.cloneStep.productionStep.forEach((step, index) => {
    //         // Supplier partner
    //         const checkboxLableSupplier= document.getElementById("checkbox-lable-showsupplier-" + this.displayName + index) as HTMLInputElement;
    //         if (checkboxLableSupplier) {
    //             if (step.showSupplierName && !checkboxLableSupplier.classList.contains('checked')) {
    //                 checkboxLableSupplier.classList.add("checked");
    //             } else {
    //                 checkboxLableSupplier.classList.remove("checked");
    //             }
    //         }

    //         // Supplier profile
    //         const checkboxLableProfile= document.getElementById("checkbox-lable-showprofile-" + this.displayName + index) as HTMLInputElement;
    //         if (checkboxLableProfile) {
    //             if (step.showProfile && !checkboxLableProfile.classList.contains('checked')) {
    //                 checkboxLableProfile.classList.add("checked");
    //             } else {
    //                 checkboxLableProfile.classList.remove("checked");
    //             }
    //         }

    //         // Supplier contact address
    //         const checkboxLableAddress= document.getElementById("checkbox-lable-showFactoryAddress-" + this.displayName + index) as HTMLInputElement;
    //         if (checkboxLableAddress) {
    //             if (step.showFactoryAddress && !checkboxLableAddress.classList.contains('checked')) {
    //                 checkboxLableAddress.classList.add("checked");
    //             } else {
    //                 checkboxLableAddress.classList.remove("checked");
    //             }
    //         }
    //     })
    // }

    // Not required after TTD-5022
    // private checkStepStatus() {
    //     const checkboxLable = document.getElementById("checkbox-lable-" + this.cloneStep.sequence) as HTMLInputElement;
    //     checkboxLable.classList.toggle("checked");
    //     const checkbox = document.getElementById("checkbox-newsletter-" + this.cloneStep.sequence) as HTMLInputElement;
    //     if (checkboxLable.classList.contains('checked')) {
    //         checkbox.checked = true;
    //         this.cloneStep.showStep = true;
    //         this.cloneStep.productionStep.forEach((step, index) => {
    //             // For Supplier name
    //             const checkboxLableSupplier = document.getElementById("checkbox-lable-showsupplier-" + this.displayName + index) as HTMLInputElement;
    //             checkboxLableSupplier.classList.add("checked");
    //             this.cloneStep.productionStep[index].showSupplierName = true;

    //             if(step.supplier.profile !== "" && step.supplier.profile !== null && step.supplier.profile !== undefined){
    //                 if((step.supplier.profile && !step.isPublished) || (step.supplier.profile && step.isPublished && step.showProfile)){
    //                     // For Supplier profile
    //                     const checkboxLableProfile = document.getElementById("checkbox-lable-showprofile-" + this.displayName + index) as HTMLInputElement;
    //                     checkboxLableProfile.classList.add("checked");
    //                     this.cloneStep.productionStep[index].showProfile = true;
    //                 }
    //             } else {
    //                 this.cloneStep.productionStep[index].showProfile = false;
    //             }

    //             if((step.supplier.name && !step.isPublished) || (step.supplier.name && step.isPublished && step.showFactoryAddress)){
    //                 // For Supplier factory address
    //                 const checkboxLableAddress = document.getElementById("checkbox-lable-showFactoryAddress-" + this.displayName + index) as HTMLInputElement;
    //                 checkboxLableAddress.classList.add("checked");
    //                 this.cloneStep.productionStep[index].showFactoryAddress = true;
    //             }

    //             // For newsletter
    //             this.cloneStep.productionStep[index].showNewsLetter = true;
    //             this.cloneStep.productionStep[index].showDetails = true;
    //         })
    //     } else {
    //         checkbox.checked = false;
    //         this.cloneStep.showStep = false;
    //         this.cloneStep.productionStep.forEach((step, index) => {
    //             // For Supplier name
    //             const checkboxLableSupplier = document.getElementById("checkbox-lable-showsupplier-" + this.displayName + index) as HTMLInputElement;
    //             checkboxLableSupplier.classList.remove("checked");
    //             this.cloneStep.productionStep[index].showSupplierName = false;

    //             if(step.supplier.profile !== "" && step.supplier.profile !== null && step.supplier.profile !== undefined){
    //                 if((step.supplier.profile && !step.isPublished) || (step.supplier.profile && step.isPublished && step.showProfile)){
    //                     // For Supplier profile
    //                     const checkboxLableProfile = document.getElementById("checkbox-lable-showprofile-" + this.displayName + index) as HTMLInputElement;
    //                     checkboxLableProfile.classList.remove("checked");
    //                     this.cloneStep.productionStep[index].showProfile = false;
    //                 }
    //             }

    //             if((step.supplier.name && !step.isPublished) || (step.supplier.name && step.isPublished && step.showFactoryAddress)){
    //                 // For Supplier address
    //                 const checkboxLableAddress = document.getElementById("checkbox-lable-showFactoryAddress-" + this.displayName + index) as HTMLInputElement;
    //                 checkboxLableAddress.classList.remove("checked");
    //                 this.cloneStep.productionStep[index].showFactoryAddress = false;
    //             }

    //             // For newsletter
    //             this.cloneStep.productionStep[index].showNewsLetter = false;
    //             this.cloneStep.productionStep[index].showDetails = false;
    //         })
    //     }
    //     this.updatedStepDetails(this.cloneStep, this.product);
    // }

    private filteredSteps(steps: ProductionStepModel[]): ProductionStepModel[] {
        const finalSteps: ProductionStepModel[] = [];
        steps.forEach(step => {
            if (!step.isPublished || (step.isPublished && step.showSupplierName)) {
                finalSteps.push(step);
            }
        });
        return finalSteps;
    }

    // Not required after TTD-5022
    // private checkStepPartnerStatus(type: string, id: string): void {
    //     const index = this.cloneStep.productionStep.findIndex(s => s.ID === id);
    //     let checkboxLable: HTMLInputElement;
    //     switch (type) {
    //         case 'supplier':
    //             checkboxLable = document.getElementById("checkbox-lable-showsupplier-" + this.displayName + index) as HTMLInputElement;
    //             checkboxLable.classList.toggle("checked");
    //             if (checkboxLable.classList.contains('checked')) {
    //                 this.cloneStep.productionStep[index].showSupplierName = true;

    //                 // For Supplier profile
    //                 if (this.cloneStep.productionStep[index].supplier.profile !== "") {
    //                     const checkboxLableProfile = document.getElementById("checkbox-lable-showprofile-" + this.displayName + index) as HTMLInputElement;
    //                     checkboxLableProfile.classList.add("checked");
    //                     this.cloneStep.productionStep[index].showProfile = true;
    //                 } else {
    //                     this.cloneStep.productionStep[index].showProfile = false;
    //                 }

    //                 // For Supplier profile
    //                 const checkboxLableAddress = document.getElementById("checkbox-lable-showFactoryAddress-" + this.displayName + index) as HTMLInputElement;
    //                 checkboxLableAddress.classList.add("checked");
    //                 this.cloneStep.productionStep[index].showFactoryAddress = true;
    //             } else {
    //                 this.cloneStep.productionStep[index].showSupplierName = false;

    //                 // For Supplier profile
    //                 if (this.cloneStep.productionStep[index].supplier.profile !== "") {
    //                     const checkboxLableProfile = document.getElementById("checkbox-lable-showprofile-" + this.displayName + index) as HTMLInputElement;
    //                     checkboxLableProfile.classList.remove("checked");
    //                     this.cloneStep.productionStep[index].showProfile = false;
    //                 }

    //                 // For Supplier profile
    //                 const checkboxLableAddress = document.getElementById("checkbox-lable-showFactoryAddress-" + this.displayName + index) as HTMLInputElement;
    //                 checkboxLableAddress.classList.remove("checked");
    //                 this.cloneStep.productionStep[index].showFactoryAddress = false;
    //             }
    //             if(!this.cloneStep.productionStep[index].showSupplierName && !this.cloneStep.productionStep[index].showProfile && !this.cloneStep.productionStep[index].showFactoryAddress){
    //                 this.cloneStep.productionStep[index].showDetails = false;
    //             } else {
    //                 this.cloneStep.productionStep[index].showDetails = true;
    //             }
    //             break;

    //         case 'profile':
    //             checkboxLable = document.getElementById("checkbox-lable-showprofile-" + this.displayName + index) as HTMLInputElement;
    //             checkboxLable.classList.toggle("checked");
    //             if (checkboxLable.classList.contains('checked')) {
    //                 this.cloneStep.productionStep[index].showProfile = true;
    //                 // For Supplier
    //                 const checkboxLableSupplier = document.getElementById("checkbox-lable-showsupplier-" + this.displayName + index) as HTMLInputElement;
    //                 checkboxLableSupplier.classList.add("checked");
    //                 this.cloneStep.productionStep[index].showSupplierName = true;
    //             } else {
    //                 this.cloneStep.productionStep[index].showProfile = false;
    //             }
    //             if(!this.cloneStep.productionStep[index].showSupplierName && !this.cloneStep.productionStep[index].showProfile && !this.cloneStep.productionStep[index].showFactoryAddress){
    //                 this.cloneStep.productionStep[index].showDetails = false;
    //             } else {
    //                 this.cloneStep.productionStep[index].showDetails = true;
    //             }
    //             break;

    //         case 'address':
    //             checkboxLable = document.getElementById("checkbox-lable-showFactoryAddress-" + this.displayName + index) as HTMLInputElement;
    //             checkboxLable.classList.toggle("checked");
    //             if (checkboxLable.classList.contains('checked')) {
    //                 this.cloneStep.productionStep[index].showFactoryAddress = true;
    //                 // For Supplier
    //                 const checkboxLableSupplier = document.getElementById("checkbox-lable-showsupplier-" + this.displayName + index) as HTMLInputElement;
    //                 checkboxLableSupplier.classList.add("checked");
    //                 this.cloneStep.productionStep[index].showSupplierName = true;
    //             } else {
    //                 this.cloneStep.productionStep[index].showFactoryAddress = false;
    //             }
    //             if(!this.cloneStep.productionStep[index].showSupplierName && !this.cloneStep.productionStep[index].showProfile && !this.cloneStep.productionStep[index].showFactoryAddress){
    //                 this.cloneStep.productionStep[index].showDetails = false;
    //             } else {
    //                 this.cloneStep.productionStep[index].showDetails = true;
    //             }
    //             break;
    //     }
    //     this.checkUncheckParent();
    //     this.updatedStepDetails(this.cloneStep, this.product);
    // }

    // Not required after TTD-5022
    // private checkUncheckParent(): void {
    //     if (this.cloneStep.productionStep.filter(f => f.showSupplierName).length !== 0) {
    //         const checkboxLable = document.getElementById("checkbox-lable-" + this.cloneStep.sequence) as HTMLInputElement;
    //         if (!checkboxLable.classList.contains('checked')) {
    //             checkboxLable.classList.add("checked");
    //         }
    //     } else {
    //         const checkboxLable = document.getElementById("checkbox-lable-" + this.cloneStep.sequence) as HTMLInputElement;
    //         checkboxLable.classList.remove("checked");
    //     }
    // }

    // private checkStatusFactoryAddress() {
    //     const checkboxLable = document.getElementById("checkbox-lable-showFactoryAddress-" + this.step.sequence) as HTMLInputElement;
    //     checkboxLable.classList.toggle("checked");
    //     // if (checkboxLable.classList.contains('checked')) {
    //     //     this.step.showFactoryAddress = true;
    //     // } else {
    //     //     this.step.showFactoryAddress = false;
    //     // }
    // }

    private get profilePictureSrc(): string {
        if (this.supplier == null || this.supplier.profilePics == null) {
            return "";
        }
        const mainPicture = this.supplier.profilePics.filter(p => p.isMainPicture)[0];
        if (mainPicture == null) {
            if (this.supplier.profilePics.length > 0) {
                return this.supplier.profilePics[0].content;
            }
            return "";
        }
        return mainPicture.content;
    }

    private get defaultStepImage(): string {
        const img = require.context('../assets/steps/', false, /\.(jpg|png|svg)$/);
        switch (this.step.displayName) {
            case 'Cotton harvest':
                return img('./' + 'harvest' + ".svg");
            case 'Spinning':
                return img('./' + 'spinning' + ".svg");
            case 'Assembling':
                return img('./' + 'assembling' + ".svg");
            case 'Washing':
                return img('./' + 'washing' + ".svg");
            case 'Confectioning':
                return img('./' + 'Confectioning' + ".svg");
            case 'Transport':
                return img('./' + 'transport' + ".svg");
            case 'Knitting':
                return img('./' + 'knitting' + ".svg");
            case 'Tanning':
                return img('./' + 'tanning' + ".svg");
            case 'Weaving':
                return img('./' + 'weaving' + ".svg");
            case 'Dyeing':
                return img('./' + 'dyeing' + ".svg");
            case 'Printing':
                return img('./' + 'printing' + ".svg");
            case 'Embroidery':
                return img('./' + 'embroidery' + ".svg");
            case 'Ginning':
                return img('./' + 'ginning' + ".svg");
            case 'Importing':
                return img('./' + 'importing' + ".svg");
            case 'Warehousing':
                return img('./' + 'warehousing' + ".svg");
            case 'Design & development':
                return img('./' + 'design' + ".svg");
            case 'Raw material':
                return img('./' + 'raw-material' + ".svg");
            case 'Recycled material':
                return img('./' + 'recycled-material' + ".svg");
            case 'Accessories':
                return img('./' + 'accessories' + ".svg");
            case 'Trims':
                return img('./' + 'trims' + ".svg");
            case 'Retailing':
                return img('./' + 'retailing' + ".svg");
            case 'Material trading':
                return img('./' + 'material-trading' + ".svg");
            case 'Natural material':
                return img('./' + 'natural_material' + ".svg");
            case 'Yarn trading':
                return img('./' + 'yarn-trading' + ".svg");
            case 'Fabric trading':
                return img('./' + 'fabric-trading' + ".svg");
            case 'Cutting':
                return img('./' + 'cutting' + ".svg");
            case 'Sewing':
                return img('./' + 'Confectioning' + ".svg");
            case 'Finishing':
                return img('./' + 'finishing' + ".svg");
            case 'Packing':
                return img('./' + 'packing' + ".svg");
            case 'Labelling':
                return img('./' + 'labelling' + ".svg");
            case 'Exporting':
                return img('./' + 'exporting' + ".svg");
            case 'Man-made material':
                return img('./' + 'man-made-material' + ".svg");
        }
        return '';
    }

    private checkAddressUnavailable(supplier: SupplierModel): boolean {
        return supplier == null || supplier.factoryAddresses == null || supplier.factoryAddresses.length === 0;
    }

    private contactLine1(supplier: SupplierModel): string {
        if (this.checkAddressUnavailable(supplier)) {
            return "-";
        }
        return supplier.factoryAddresses[0].line1;
    }

    private contactLine2(supplier: SupplierModel): string {
        if (this.checkAddressUnavailable(supplier)) {
            return "-";
        }
        if (supplier.factoryAddresses[0].country) {
            const country = this.$t(`countries.${supplier.factoryAddresses[0].country}`);
            if (supplier.factoryAddresses[0].city && country) {
                return `${supplier.factoryAddresses[0].postal} ${supplier.factoryAddresses[0].city}, ${country}`;
            } else if (!supplier.factoryAddresses[0].city && !supplier.factoryAddresses[0].postal && country) {
                return `${country}`;
            }
        } else if (supplier.factoryAddresses[0].postal && supplier.factoryAddresses[0].city) {
            return `${supplier.factoryAddresses[0].postal} ${supplier.factoryAddresses[0].city}`;
        }

        return '';
    }

    // private get supplierProfile(): string {
    //     if (this.supplier == null) {
    //         return "-";
    //     }
    //     return this.supplier.profile;
    // }

    private address(supplier: SupplierModel, addressType: string): string {
        if (supplier == null || supplier.factoryAddresses == null || supplier.factoryAddresses.length === 0) {
            return "-";
        }

        let country = '';
        if(supplier.factoryAddresses[0].country.length === 2){
            country = this.$t(`countries.${supplier.factoryAddresses[0].country}`).toString();
        } else {
            country = supplier.factoryAddresses[0].country;
        }
        if(addressType === 'FULL-ADDRESS'){
            if(supplier.factoryAddresses[0].line1 !== '' && supplier.factoryAddresses[0].postal !== ''){
                return `${supplier.factoryAddresses[0].line1}, ${supplier.factoryAddresses[0].line2 !== '' ? supplier.factoryAddresses[0].line2 + ', ' : ''} ${supplier.factoryAddresses[0].postal}, ${supplier.factoryAddresses[0].city}, ${supplier.factoryAddresses[0].state !== '' ? supplier.factoryAddresses[0].state + ', ' : ''} ${country}`
            } else {
                return `${supplier.factoryAddresses[0].city}, ${supplier.factoryAddresses[0].state !== '' ? supplier.factoryAddresses[0].state + ', ' : ''} ${country}`;
            }
        }
        if(addressType === 'CITY_STATE_COUNTRY'){
            return `${supplier.factoryAddresses[0].city}, ${supplier.factoryAddresses[0].state !== '' ? supplier.factoryAddresses[0].state + ', ' : ''} ${country}`;
        }
        if(addressType === 'STATE_COUNTRY'){
            return `${supplier.factoryAddresses[0].state !== '' ? supplier.factoryAddresses[0].state + ', ' : ''} ${country}`;
        }
        if(addressType === 'COUNTRY'){
            return `${country}`;
        }
        return '';
    }

    private get isActive(): boolean {
        if (this.stepHtmlElement == null) {
            return false;
        }
        const range = this.height / this.windowHeight;
        const gridTop = this.windowHeight * .4;
        const gridBottom = this.windowHeight * (.4 + range);
        const offsetTop = this.stepHtmlElement.offsetTop - this.scrollPosition;
        if (offsetTop > gridTop && offsetTop < gridBottom) {
            // this.activeStep(this.step);
            return true;
        }
        return false;
    }

    private defaultStepBannerImage(stepName: string): string {
        const defaultImageForStep = require.context('../assets/steps/', false, /\.(jpg|png)$/);
        switch (this.step.displayName) {
            case 'Cotton harvest':
                return defaultImageForStep('./' + 'Cotton_Harvest' + ".jpg");
            case 'Spinning':
                return defaultImageForStep('./' + 'Spinning' + ".png");
            case 'Washing':
                return defaultImageForStep('./' + 'Washing' + ".png");
            case 'Confectioning':
                return defaultImageForStep('./' + 'Confectioning' + ".png");
            case 'Transport':
                return defaultImageForStep('./' + 'Transport' + ".png");
            case 'Knitting':
                return defaultImageForStep('./' + 'Knitting' + ".jpg");
            case 'Weaving':
                return defaultImageForStep('./' + 'Weaving' + ".png");
            case 'Dyeing':
                return defaultImageForStep('./' + 'Dyeing' + ".png");
            case 'Printing':
                return defaultImageForStep('./' + 'Printing' + ".png");
            case 'Embroidery':
                return defaultImageForStep('./' + 'Embroidery' + ".png");
            case 'Ginning':
                return defaultImageForStep('./' + 'Ginning' + ".png");
            case 'Importing':
                return defaultImageForStep('./' + 'Importing' + ".png");
            case 'Warehousing':
                return defaultImageForStep('./' + 'Warehousing' + ".png");
            case 'Design & development':
                return defaultImageForStep('./' + 'Design_Development' + ".png");
            case 'Man-made material':
                return defaultImageForStep('./' + 'Man_made_material' + ".png");
            case 'Recycled material':
                return defaultImageForStep('./' + 'Recycled_Material' + ".png");
            case 'Accessories':
                return defaultImageForStep('./' + 'Accessories' + ".png");
            case 'Trims':
                return defaultImageForStep('./' + 'Trims' + ".png");
            case 'Retailing':
                return defaultImageForStep('./' + 'Retailing' + ".png");
            case 'Material trading':
                return defaultImageForStep('./' + 'Material_trading' + ".png");
            case 'Natural material':
                return defaultImageForStep('./' + 'Natural_material' + ".png");
            case 'Yarn trading':
                return defaultImageForStep('./' + 'Yarn_trading' + ".png");
            case 'Fabric trading':
                return defaultImageForStep('./' + 'Fabric_trading' + ".png");
            case 'Cutting':
                return defaultImageForStep('./' + 'Cutting' + ".png");
            case 'Sewing':
                return defaultImageForStep('./' + 'Sewing' + ".png");
            case 'Finishing':
                return defaultImageForStep('./' + 'Finishing' + ".jpg");
            case 'Packing':
                return defaultImageForStep('./' + 'Packing' + ".png");
            case 'Labelling':
                return defaultImageForStep('./' + 'Labelling' + ".png");
            case 'Exporting':
                return defaultImageForStep('./' + 'Exporting' + ".png");
        }
        return '';
    }

    private getSliderImages(stepName: string): string[] {
        const sliderImages: string[] = [];
        sliderImages.push(this.defaultStepBannerImage(stepName));
        return sliderImages;
        // if (this.supplier == null || this.supplier.profilePics == null || this.supplier.profilePics.length === 0) {
        //     this.sliderImages.push(this.defaultStepBannerImage(stepName));
        //     return this.sliderImages;
        // } else if (this.supplier.profilePics.length === 1) {
        //     this.sliderImages.push(this.supplier.profilePics[0].content);
        //     return this.sliderImages;
        // } else {
        //     let mainPicture = "";
        //     for (let i = 0; i < this.supplier.profilePics.length; i++) {
        //         if (this.supplier.profilePics[i].isMainPicture == false) {
        //             this.sliderImages.push(this.supplier.profilePics[i].content);
        //         } else {
        //             mainPicture = this.supplier.profilePics[i].content;
        //         }
        //     }
        //     this.sliderImages.unshift(mainPicture);
        //     return this.sliderImages;
        // }
    }

    private showDetails(): void {
        // this.showDetailsPage(this.supplier, this.step, this.product);
    }

    // Updated in Sprint 22, TTD-4218 -> TTD-4325
    private toggleStep(): void {
        // this.showStepPartners = !this.showStepPartners;
        // this.cloneStep.showStep = !this.cloneStep.showStep;
        // if(this.cloneStep.showStep) {
        //     this.cloneStep.showStep = false;
        // } else {
            this.hideOtherSteps(this.cloneStep);
        // }
    }

    // Sprint 22, TTD-4210
    @Emit()
    private activeStep(step: ProductionStepModel): void { }

    // Sprint 22, TTD-4210
    @Emit()
    private removeActiveStep(): void { }

    // Sprint 22, TTD-4210
    @Emit()
    private hideOtherSteps(step: ProductionStepSuppliersModel): void { }

    // Not required after TTD-5022
    // @Emit()
    // private updatedStepDetails(step: ProductionStepSuppliersModel, product: OrderLineDisplayModel): void { }

    // Sprint 22, TTD-4218 -> TTD-4325
    @Watch("step", { deep: true })
    private stepChanged(): void {
        if (!this.step.isCollapsed) {
            this.isShowStep = true;
        } else {
            this.isShowStep = false;
        }
    }
}
