import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { ProductionStepModel } from '@/models/productionStepModel';
import { ImageStoreConfiguration } from '@/config';
import { ClientModel } from '@/models/clientModel';

@Component
export default class ProductDetails extends Vue {

    @Prop()
    private product!: OrderLineDisplayModel;

    @Prop()
    private steps!: ProductionStepModel[];

    @Prop()
    private client!: ClientModel;

    @Prop()
    private windowHeight!: number;

    @Prop()
    private scrollPosition!: number;

    private brandLogoBaseUrl: string | undefined = ImageStoreConfiguration.baseUrl;

    private industryStd: string | undefined = process.env.VUE_APP_IndustryStd;

    private showFootprint: boolean = false;

    private waterPercentage: number = 0;

    private waterDashArray: string = "";

    private energyPercentage: number = 0;

    private energyDashArray: string = "";

    private landPercentage: number = 0;

    private landDashArray: string = "";

    private savingBathtubs: string = "";

    private savingKM: string = "";

    private savingLandInM2: string = "";

    private productPictureAvailable: boolean = false;

    // private scrollPosition: number = 0;

    private hoverIndex: number = -1;

    private detailsContent: string = "";

    private savingOneXaxis: number = 0;

    private filledXaxis: number = 0;

    private savingTwoXaxis: number = 0;

    private kmXaxis: number = 0;

    private landXaxis: number = 78;

    private footprintWaterCheckbox: boolean = false;

    private footprintLandCheckbox: boolean = false;

    private footprintEnergyCheckbox: boolean = false;

    private footprintAllCheckbox: boolean = false;

    private blockClass: boolean = false;

    private maxlength: number = 8;

    private showReadMore: boolean = false;

    private showReadLess: boolean = false;

    private async mounted(): Promise<void> {

        if (this.product.product.productPictureUrls.length > 0) {
            this.productPictureAvailable = true;
        } else {
            this.productPictureAvailable = false;
        }

        if (this.product.footprint.length > 0) {
            if(this.product.product.isPublished && (this.product.footprint[0]['allFootprintIcon'] === "HIDE" || (this.product.footprint[0]['showCO2Icon'] === "HIDE" && this.product.footprint[0]['showLandIcon'] === "HIDE" && this.product.footprint[0]['showWaterIcon'] === "HIDE"))){
                this.showFootprint = false;
                return;
            }
            this.footprintAllCheckbox = this.product.footprint[0]['allFootprintIcon'] === '' || this.product.footprint[0]['allFootprintIcon'] === "SHOW" ? true : false;
            const bathtubs = Number(this.product.footprint[0]['savingBathtubs']);
            this.savingBathtubs = bathtubs.toFixed();
            if(this.savingBathtubs.length==1) {
                this.savingOneXaxis = 38;
                this.filledXaxis = 62;
            } else if(this.savingBathtubs.length==2) {
                this.savingOneXaxis = 38;
                this.filledXaxis = 65;
            } else if(this.savingBathtubs.length==3) {
                this.savingOneXaxis = 38;
                this.filledXaxis = 67;
            } else {
                this.savingOneXaxis = 38;
                this.filledXaxis = 67;
            }

            const km = Number(this.product.footprint[0]['savingKM']);
            this.savingKM = km.toFixed();
            // if(this.savingKM.length==1) {
            //     this.savingTwoXaxis = 38;
            //     this.kmXaxis = 55;
            // } else if(this.savingKM.length==2 || this.savingKM.length==3) {
            //     this.savingTwoXaxis = 37;
            //     this.kmXaxis = 57;
            // } else if(this.savingKM.length==4) {
            //     this.savingTwoXaxis = 35;
            //     this.kmXaxis = 57;
            // }
            const landInM2 = Number(this.product.footprint[0]['savingLandInM2']);
            this.savingLandInM2 = landInM2.toFixed();
            if(this.savingLandInM2.length<3) {
                this.landXaxis = 74;
            }

            const progressBar = document.getElementById('circular-progress') as HTMLElement;
            const progressBarCar = document.getElementById('circular-progress-car') as HTMLElement;
            const progressBarLand = document.getElementById('circular-progress-land') as HTMLElement;

            //For water circle
            let progressBarValue = 0;
            let parseString = Number(this.product.footprint[0]['savingWaterPerLitre']);
            let progressbarEndValue = Number(parseString.toFixed());
            this.waterPercentage = progressbarEndValue;
            this.waterDashArray = `${progressbarEndValue * 2.5}, 250`;
            if (this.product.footprint[0]['showWaterIcon'] === "SHOW") {
                this.footprintWaterCheckbox = true;
            } else if (this.product.footprint[0]['showWaterIcon'] === "" && this.waterPercentage !== 0) {
                this.footprintWaterCheckbox = true;
            } else {
                this.footprintWaterCheckbox = false;
            }
            // this.footprintWaterCheckbox = (this.product.footprint[0]['showWaterIcon'] === "SHOW" || this.product.footprint[0]['showWaterIcon'] === "") && this.waterPercentage !== 0 ? true : false;

            //For energy circle
            let progressBarCarValue = 0;
            let parseStringCar = Number(this.product.footprint[0]['savingCO2PerGram']);
            let progressbarCarEndValue = Number(parseStringCar.toFixed());
            this.energyPercentage = progressbarCarEndValue;
            this.energyDashArray = `${progressbarCarEndValue * 2.5}, 250`;
            if (this.product.footprint[0]['showCO2Icon'] === "SHOW") {
                this.footprintEnergyCheckbox = true;
            } else if (this.product.footprint[0]['showCO2Icon'] === "" && this.energyPercentage !== 0) {
                this.footprintEnergyCheckbox = true;
            } else {
                this.footprintEnergyCheckbox = false;
            }
            // this.footprintEnergyCheckbox = (this.product.footprint[0]['showCO2Icon'] === "SHOW" || this.product.footprint[0]['showCO2Icon'] === "") && this.energyPercentage !== 0 ? true : false;

            //For land circle
            let progressBarLandValue = 0;
            let parseStringLand = Number(this.product.footprint[0]['savingLandUse']);
            let progressbarLandEndValue = Number(parseStringLand.toFixed());
            this.landPercentage = progressbarLandEndValue;
            this.landDashArray = `${progressbarLandEndValue * 2.5}, 250`;
            if (this.product.footprint[0]['showLandIcon'] === "SHOW") {
                this.footprintLandCheckbox = true;
            } else if (this.product.footprint[0]['showLandIcon'] === "" && this.landPercentage !== 0) {
                this.footprintLandCheckbox = true;
            } else {
                this.footprintLandCheckbox = false;
            }
            // this.footprintLandCheckbox = (this.product.footprint[0]['showLandIcon'] === "SHOW" || this.product.footprint[0]['showLandIcon'] === "") && this.landPercentage !== 0 ? true : false;

            if (!this.footprintWaterCheckbox && !this.footprintEnergyCheckbox && !this.footprintLandCheckbox) {
                this.footprintAllCheckbox = false;
                if (this.product.product.isPublished === true) {
                    this.showFootprint = false;
                } else {
                    this.showFootprint = true;
                }
            } else {
                this.showFootprint = true;
            }
            // this.setFootprintCheckboxStatus();
        } else {
            this.showFootprint = false;
        }
    }

    private created(): void {
        let element = document.getElementById('product-details-desc') as HTMLElement;
        this.detailsContent = this.product.product.compositionMainFabric + " " + this.product.product.compositionSecondFabric;
        if(screen.width<536){
            this.maxlength = 3;
        }
        if (this.detailsContent.split(' ').length > this.maxlength) {
            this.detailsContent = this.detailsContent.split(' ').slice(0, this.maxlength).join(' ') + " ... ";
            this.showReadMore = true;
            this.showReadLess = false;
        } else {
            this.blockClass = true;
        }
    }

    private readMore(): void {
        this.detailsContent = this.product.product.compositionMainFabric + " " + this.product.product.compositionSecondFabric + " ";
        this.showReadMore = false;
        this.showReadLess = true;
    }

    private readLess(): void {
        this.detailsContent = this.detailsContent.split(' ').slice(0, this.maxlength).join(' ') + " ... ";
        this.showReadMore = true;
        this.showReadLess = false;
    }

    private get productPictureSrc(): boolean {
        if (!this.product.product || this.product.product.productPictureUrls === undefined || this.product.product.productPictureUrls == null) {
            return false;
        }
        return true;
    }

    // private readMore() {
    //     let details = document.getElementById('product-details-desc') as HTMLElement;
    //     details.innerHTML = this.detailsContent;
    //     let readmore = document.getElementById('read-more') as HTMLElement;
    //     readmore.style.display = "none";
    // }

    private get numberOfStepsText(): number {
        let stepCnt = 0;
        this.steps.forEach(step => {
            if (step.showDetails) {
                stepCnt++;
            }
        });
        return stepCnt;
    }

    private get isSmall(): boolean {
        if (this.scrollPosition <= 10) {
            this.hoverIndex = -1;
        }

        return this.scrollPosition > 10;
    }

    private get brandLogoSrc(): string {
        const brand = this.client.topBrands.filter(t => t.brandName === this.product.product.brandName)[0];
        if (brand == null || brand.brandLogoUrl == null) {
            return "";
        }

        return `${this.brandLogoBaseUrl}${brand.brandLogoUrl}`;
    }

    private get showWater(): boolean {
        return this.product.footprint.length > 0 && this.product.product.isPublished && (this.product.footprint[0].showWaterIcon === 'HIDE' || this.waterPercentage === 0) ? false : true; //TTD-4830 urgent bug
    }

    private get showEnergy(): boolean {
        return this.product.footprint.length > 0 && this.product.product.isPublished && (this.product.footprint[0].showCO2Icon === 'HIDE' || this.energyPercentage === 0) ? false : true; //TTD-4830 urgent bug
    }

    private get showLand(): boolean {
        return this.product.footprint.length > 0 && this.product.product.isPublished && (this.product.footprint[0].showLandIcon === 'HIDE' || this.landPercentage === 0) ? false : true; //TTD-4830 urgent bug
    }

    // private setFootprintCheckboxStatus() {
    //     const checkboxLableAll = document.getElementById("checkbox-lable-all") as HTMLInputElement;
    //     if (this.footprintAllCheckbox && !checkboxLableAll.classList.contains('checked')) {
    //         checkboxLableAll.classList.add("checked");
    //     } else {
    //         checkboxLableAll.classList.remove("checked");
    //     }

    //     const checkboxLableWater = document.getElementById("checkbox-lable-water") as HTMLInputElement;
    //     if (this.footprintWaterCheckbox && !checkboxLableWater.classList.contains('checked')) {
    //         checkboxLableWater.classList.add("checked");
    //     } else {
    //         checkboxLableWater.classList.remove("checked");
    //     }

    //     const checkboxLableEnergy = document.getElementById("checkbox-lable-energy") as HTMLInputElement;
    //     if (this.footprintEnergyCheckbox && !checkboxLableEnergy.classList.contains('checked')) {
    //         checkboxLableEnergy.classList.add("checked");
    //     } else {
    //         checkboxLableEnergy.classList.remove("checked");
    //     }

    //     const checkboxLableLand = document.getElementById("checkbox-lable-land") as HTMLInputElement;
    //     if (this.footprintLandCheckbox && !checkboxLableLand.classList.contains('checked')) {
    //         checkboxLableLand.classList.add("checked");
    //     } else {
    //         checkboxLableLand.classList.remove("checked");
    //     }

    //     this.footprintCheckboxState(this.footprintAllCheckbox, this.footprintWaterCheckbox, this.footprintEnergyCheckbox, this.footprintLandCheckbox);
    // }

    // private checkStatus(type: string) {
    //     const checkboxLable = document.getElementById("checkbox-lable-" + type) as HTMLInputElement;
    //     const checkboxLableAll = document.getElementById("checkbox-lable-all") as HTMLInputElement;
    //     const checkboxLableWater = document.getElementById("checkbox-lable-water") as HTMLInputElement;
    //     const checkboxLableEnergy = document.getElementById("checkbox-lable-energy") as HTMLInputElement;
    //     const checkboxLableLand = document.getElementById("checkbox-lable-land") as HTMLInputElement;
    //     switch (type) {
    //         case 'water':
    //             checkboxLable.classList.toggle("checked");
    //             if (checkboxLable.classList.contains('checked')) {
    //                 this.footprintWaterCheckbox = true;
    //                 //all
    //                 this.footprintAllCheckbox = true;
    //                 checkboxLableAll.classList.add("checked");
    //             } else {
    //                 this.footprintWaterCheckbox = false;
    //                 if(!checkboxLableEnergy.classList.contains('checked') && !checkboxLableLand.classList.contains('checked')) {
    //                     this.footprintAllCheckbox = false;
    //                     checkboxLableAll.classList.remove("checked");
    //                 }
    //             }
    //             break;

    //         case 'energy':
    //             checkboxLable.classList.toggle("checked");
    //             if (checkboxLable.classList.contains('checked')) {
    //                 this.footprintEnergyCheckbox = true;
    //                 //all
    //                 this.footprintAllCheckbox = true;
    //                 checkboxLableAll.classList.add("checked");
    //             } else {
    //                 this.footprintEnergyCheckbox = false;
    //                 if(!checkboxLableWater.classList.contains('checked') && !checkboxLableLand.classList.contains('checked')) {
    //                     this.footprintAllCheckbox = false;
    //                     checkboxLableAll.classList.remove("checked");
    //                 }
    //             }
    //             break;

    //         case 'land':
    //             checkboxLable.classList.toggle("checked");
    //             if (checkboxLable.classList.contains('checked')) {
    //                 this.footprintLandCheckbox = true;
    //                 //all
    //                 this.footprintAllCheckbox = true;
    //                 checkboxLableAll.classList.add("checked");
    //             } else {
    //                 this.footprintLandCheckbox = false;
    //                 if(!checkboxLableWater.classList.contains('checked') && !checkboxLableEnergy.classList.contains('checked')) {
    //                     this.footprintAllCheckbox = false;
    //                     checkboxLableAll.classList.remove("checked");
    //                 }
    //             }
    //             break;

    //         case 'all':
    //             checkboxLable.classList.toggle("checked");
    //             if (checkboxLable.classList.contains('checked')) {
    //                 this.footprintAllCheckbox = true;
    //                 // water
    //                 this.footprintWaterCheckbox = true;
    //                 checkboxLableWater.classList.add("checked");

    //                 //energy
    //                 this.footprintEnergyCheckbox = true;
    //                 checkboxLableEnergy.classList.add("checked");

    //                 //land
    //                 this.footprintLandCheckbox = true;
    //                 checkboxLableLand.classList.add("checked");
    //             } else {
    //                 this.footprintAllCheckbox = false;
    //                 // water
    //                 this.footprintWaterCheckbox = false;
    //                 checkboxLableWater.classList.remove("checked");

    //                 //energy
    //                 this.footprintEnergyCheckbox = false;
    //                 checkboxLableEnergy.classList.remove("checked");

    //                 //land
    //                 this.footprintLandCheckbox = false;
    //                 checkboxLableLand.classList.remove("checked");
    //             }
    //             break;
    //     }
    //     this.footprintCheckboxState(this.footprintAllCheckbox, this.footprintWaterCheckbox, this.footprintEnergyCheckbox, this.footprintLandCheckbox);
    // }

    @Emit()
    private footprintCheckboxState(footprintAll: boolean, footprintWater: boolean, footprintEnergy: boolean, footprintLand: boolean) : void {}
}
